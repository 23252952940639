var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"mt-5",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":_vm.isAdmin || _vm.isAmbUser ? 6 : 12}},[(_vm.isClient)?_c('v-btn',{staticClass:"mr-3",attrs:{"color":"primary","depressed":"","fab":"","outlined":"","small":""},on:{"click":_vm.goBackToClientDetails}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1):_vm._e(),_c('div',{staticClass:"title-section"},[_vm._v(" VIDEOCONFERENCIA ")])],1),(_vm.isAdmin || _vm.isAmbUser)?_c('v-col',{attrs:{"cols":"6","align":"right"}},[_c('v-btn',{staticClass:"normal-btn ml-1",attrs:{"color":"primary","dark":"","tile":"","depressed":""},on:{"click":_vm.createRoom}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-google-classroom")]),_vm._v(" Crear Sala ")],1)],1):_vm._e()],1),_c('v-data-table',{staticClass:"mt-5",attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.videoconferences},scopedSlots:_vm._u([{key:"item.client",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v("mdi-account-circle-outline")]),_c('b',{domProps:{"textContent":_vm._s(item.client)}})]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v("mdi-calendar-outline")]),_vm._v(" "+_vm._s(item.date)+" ")]}},{key:"item.quantityUsers",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v("mdi-account-outline")]),_vm._v(" "+_vm._s(item.quantityUsers)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.isAdmin)?_c('v-btn',{staticClass:"mr-1 my-1",attrs:{"fab":"","outlined":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e(),(_vm.isAdmin)?_c('v-btn',{staticClass:"mr-1 my-1",attrs:{"fab":"","outlined":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-delete")])],1):_vm._e(),(_vm.isAvailableConference(item))?[_c('v-btn',{staticClass:"mr-1 my-1",attrs:{"fab":"","outlined":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.goToRoom(item)}}},[_c('v-icon',{attrs:{"color":"info"}},[_vm._v("mdi-video-outline")])],1),_c('v-btn',{staticClass:"my-1",attrs:{"fab":"","outlined":"","x-small":""},on:{"click":function($event){$event.stopPropagation();return _vm.copyRoom(item)}}},[_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-content-copy")])],1)]:_vm._e()]}}])}),_c('create-room-dialog',{ref:"creator",on:{"success":_vm.loadVideoconferences}}),_c('kimsa-confirm',{ref:"confirm",attrs:{"title":"Eliminar","content":"¿Esta seguro que desea eliminar esta Videoconferencia?","persistent":"","loading":_vm.loadingDelete,"no-close-on-confirm":""},on:{"confirm":_vm.onConfirmDelete,"cancel":function($event){_vm.currentId = null}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }