<template>
    <v-container>

        <!-- HEADER -->
        <v-row class="mt-5" align="center">
            <v-col :cols="isAdmin || isAmbUser ? 6 : 12">
                <v-btn color="primary" class="mr-3" depressed fab outlined small @click="goBackToClientDetails" v-if="isClient">
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <div class="title-section">
                    VIDEOCONFERENCIA
                </div>
            </v-col>
            <v-col cols="6" align="right" v-if="isAdmin || isAmbUser">
                <v-btn color="primary" dark tile depressed class="normal-btn ml-1" @click="createRoom">
                    <v-icon left>mdi-google-classroom</v-icon>
                    Crear Sala
                </v-btn>
            </v-col>
        </v-row>

        <!-- TABLE -->
        <v-data-table
            class="mt-5"
            :headers="headers"
            :loading="loading"
            :items="videoconferences"
        >
            <template v-slot:item.client="{ item }">
                <v-icon>mdi-account-circle-outline</v-icon>
                <b v-text="item.client"></b>
            </template>
            <template v-slot:item.date="{ item }">
                <v-icon>mdi-calendar-outline</v-icon>
                {{ item.date }}
            </template>
            <template v-slot:item.quantityUsers="{ item }">
                <v-icon>mdi-account-outline</v-icon>
                {{ item.quantityUsers }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn class="mr-1 my-1" fab outlined x-small @click.stop="editItem(item)" v-if="isAdmin">
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn class="mr-1 my-1" fab outlined x-small @click.stop="deleteItem(item)" v-if="isAdmin">
                    <v-icon color="primary">mdi-delete</v-icon>
                </v-btn>
                <template v-if="isAvailableConference(item)">
                    <v-btn class="mr-1 my-1" fab outlined x-small
                           @click.stop="goToRoom(item)">
                        <v-icon color="info">mdi-video-outline</v-icon>
                    </v-btn>
                    <v-btn class="my-1" fab outlined x-small
                           @click.stop="copyRoom(item)">
                        <v-icon color="grey">mdi-content-copy</v-icon>
                    </v-btn>
                </template>
            </template>
        </v-data-table>

        <!-- COMPONENTS -->
        <create-room-dialog @success="loadVideoconferences" ref="creator"/>

        <kimsa-confirm
            title="Eliminar"
            content="¿Esta seguro que desea eliminar esta Videoconferencia?"
            persistent
            @confirm="onConfirmDelete"
            @cancel="currentId = null"
            :loading="loadingDelete"
            no-close-on-confirm
            ref="confirm"
        />

    </v-container>
</template>

<script>
import CreateRoomDialog from "@/components/meeting/CreateRoomDialog";
import Axios from "@/utils/Axios";
import KimsaConfirm from "@/components/common/KimsaConfirm";

export default {
    name: "Videochat",
    components: {KimsaConfirm, CreateRoomDialog},
    data() {
        return {
            loading: false,
            videoconferences: [],
            currentId: null,
            loadingDelete: false,
            headers: [
                {text: 'Cliente', value: 'client', class: 'grey white--text'},
                {text: 'Nombre', value: 'name', class: 'grey white--text'},
                {text: 'Fecha', value: 'date', class: 'grey white--text'},
                {text: 'Invitados', value: 'quantityUsers', class: 'grey white--text'},
                {text: '', value: 'actions', sortable: false, class: 'grey'},
            ],
        }
    },
    async mounted() {
        const vm = this
        await vm.loadVideoconferences()
    },
    methods: {
        async loadVideoconferences() {
            const vm = this
            vm.loading = true

            await Axios.post('videoconferences').then(res => {
                // console.log('res loadVideoconferences',res)
                vm.videoconferences = res.data.result.videoconferences.map(vc => {
                    return {
                        id: vc.id,
                        client: vc.client?.name || 'n/d',
                        name: vc.name || 'n/d',
                        quantityUsers: vc.users?.length || 0,
                        date: vm.$moment(vc.date).format('DD/MM/YYYY'),
                        startHour: vc.start_hour,
                        endHour: vc.end_hour,
                        link: vc.link,
                    }
                })
            }).catch(er => {
                console.log('error loadVideoconferences', er)
            }).finally(() => {
                vm.loading = false
            })
        },
        createRoom() {
            const vm = this
            vm.$refs.creator.show()
        },
        editItem(item) {
            const vm = this
            console.log('ediiiit', item)
            vm.$refs.creator.loadVideoconference(item.id)
        },
        deleteItem(item) {
            const vm = this
            vm.$refs.confirm.show()
            vm.currentId = item.id
        },
        goToRoom(item) {
            const vm = this
            vm.$router.push({
                name: 'videochat-room',
                params: {link: item.link},
                query: {user: vm.account.name}
            })
        },
        isAvailableConference(vc) {
            let dateNow = this.$moment().format('DD/MM/YYYY')
            if (!vc.startHour || !vc.endHour || dateNow !== vc.date) return false

            let start = this.$moment(vc.startHour, 'hh:mm')
            let end = this.$moment(vc.endHour, 'hh:mm')
            let now = this.$moment()
            return this.$moment().isBetween(start, end)
        },
        async copyRoom(vc) {
            let url = `${process.env.VUE_APP_BASE_FRONT}vc/${vc.link}?user=${encodeURIComponent(this.account.name || '')}`
            await navigator.clipboard.writeText(url)
            this.toast('¡Link copiado!')
        },
        async onConfirmDelete() {
            const vm = this
            vm.loadingDelete = true

            await Axios.delete(`videoconference/${vm.currentId}/delete`).then(res => {
                // console.log('res onConfirmDelete',res)
                vm.toast('Videoconferencia eliminada')
            }).catch(er => {
                console.log('er', er)
                vm.toast('error onConfirmDelete', er)
            })

            vm.loadingDelete = false
            vm.currentId = null

            vm.$refs.confirm.hide()
            await vm.loadVideoconferences()
        },
        goBackToClientDetails() {
            const vm = this
            vm.$router.push({name: 'clients.details', params: {id: vm.account.client_id}, query: {'tab': 'Meetings'}})
        }
    }
}
</script>

<style scoped>

.title-section {
    color: var(--v-primary-base);
}

</style>