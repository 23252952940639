import { render, staticRenderFns } from "./KimsaTextareaField.vue?vue&type=template&id=7b6a6c60&scoped=true&"
import script from "./KimsaTextareaField.vue?vue&type=script&lang=js&"
export * from "./KimsaTextareaField.vue?vue&type=script&lang=js&"
import style0 from "./KimsaTextareaField.vue?vue&type=style&index=0&id=7b6a6c60&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b6a6c60",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VTextarea})
