<template>
    <div>
        <!-- TITLE + ADD ROW -->
        <v-row no-gutters align="center" class="mb-n7">
            <v-col cols="11" class="label">
                Invitados
            </v-col>
            <v-col cols="1" align="center">
                <v-btn icon color="primary" @click="addRow()">
                    <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row v-for="(user,i) in users" :key="i" align="center" class="mb-n6">
            <v-col cols="6">
                <kimsa-text-field
                    placeholder="Nombre"
                    outlined dense
                    force-value
                    :readonly="users[i].readonly"
                    :rules="nameRules"
                    :value="users[i].name"
                    @change="users[i].name = $event"
                />
            </v-col>
            <v-col cols="6">
                <kimsa-text-field
                    placeholder="Email"
                    outlined dense
                    force-value
                    :readonly="users[i].readonly"
                    :rules="emailRules"
                    inputMode="email"
                    :value="users[i].email"
                    @change="users[i].email = $event"
                    :append-icon="i !== 0 ? 'mdi-minus-circle-outline' : ''"
                    @click:append="deleteRow(i)"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import KimsaTextField from "@/components/common/inputs/KimsaTextField";
export default {
    name: "AddUserToVideoconference",
    components: {KimsaTextField},
    props: {
        values: {
            type: Array,
            default: null,
        }
    },
    data() {
        return {
            users: [],
            template: {name: '', email: ''},
            nameRules: [
                v => !!v || 'Campo requerido',
            ],
            emailRules: [
                v => !!v || 'Campo requerido',
                v => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                    return pattern.test(v) || 'Email invalido'
                }
            ],
        }
    },
    mounted() {
        const vm = this
        vm.setDefaults()
    },
    methods: {
        setDefaults() {
            const vm = this
            if (vm.values && vm.values.length > 0) vm.users = vm.values
            else {
                vm.users = []
                vm.addRow()
            }
        },
        deleteRow(index) {
            const vm = this
            vm.users.splice(index, 1)
            if(vm.users.length === 0) vm.addRow()
        },
        addRow() {
            const vm = this
            vm.users = JSON.parse(JSON.stringify(vm.users))
            vm.users.push(JSON.parse(JSON.stringify(vm.template)))
        }
    },
    watch: {
        users: {
            deep: true,
            handler() {this.$emit('change', this.users)}
        },
        values() {
            this.setDefaults()
        }
    }
}
</script>

<style scoped>

.label {
    font-size: 15px;
    color: #333333;
    font-weight: bold;
}

</style>