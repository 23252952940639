<template>
    <div>
        <span v-if="label" :class="['label', {'bolder-label': bolderLabel}]">{{ label }}</span>
        <v-textarea
            :autofocus="autofocus"
            :color="color"
            :value="model"
            @input="onChange"
            @click:clear="model = ''"
            :placeholder="placeholder"
            :rules="rules"
            :clearable="clearable"
            :readonly="readonly"
            no-resize
            rows="3"
            hide-details
            :outlined="outlined"
            :dense="dense"
            :solo="solo"
            :flat="flat"
            :class="[{'no-border': noBorder}]"
        ></v-textarea>
    </div>

</template>

<script>
export default {
    name: "KimsaTextareaField",
    props: {
        autofocus: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: '',
        },
        color: {
            type: String,
            default: 'primary'
        },
        rules: {
            type: Array,
            default: () => []
        },
        placeholder: {
            type: String,
            default: ''
        },
        value: {
            type: String,
            default: '',
        },
        outlined: {
            type: Boolean,
            default: false
        },
        flat: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        solo: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        forceValue: {
            type: Boolean,
            default: false
        },
        bolderLabel: {
            type: Boolean,
            default: false
        },
        noBorder: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            model: '',
        }
    },
    methods: {
        onChange(value) {
            const vm = this
            if (!value) return vm.model = ''

            return vm.model = value
        },
        setDefaults() {
            const vm = this
            if (vm.value || vm.forceValue) {
                vm.model = vm.value
            }
        }
    },
    mounted() {
        const vm = this
        vm.setDefaults()
    },
    watch: {
        value(val) {
            this.setDefaults()
        },
        model(val) {
            this.$emit('change', val)
        }
    }
}
</script>

<style scoped>

.label {
    font-size: 15px;
    color: #333333;
}

.bolder-label {
    font-weight: bold;
}

.no-border {
    border-radius: 0;
}

</style>