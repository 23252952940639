<template>
    <v-dialog v-model="dialog" max-width="600px" @click:outside.prevent="hide" scrollable>
        <v-card tile>
            <v-card-title>
                <v-icon color="black" left size="40">mdi-google-classroom</v-icon>
                <div class="title-dialog">
                    <div class="title-subtext">
                        {{ editMode ? 'Editar' : 'Crear' }} Sala
                    </div>
                </div>
                <v-spacer/>
                <v-btn icon color="accent" large @click="hide">
                    <v-icon large>mdi-close-circle-outline</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="8">
                        <kimsa-select
                            label="Cliente"
                            placeholder="Selecciona un cliente"
                            bolder-label
                            :items="clients"
                            clearable
                            outlined solo
                            flat dense
                            item-text="name"
                            item-value="id"
                            :readonly="editMode"
                            force-value
                            :value="client"
                            @change="client = $event"
                        />
                    </v-col>
                    <v-col cols="4">
                        <span class="label"><b>Avisos</b></span>
                        <v-checkbox
                            class="mt-1"
                            v-model="notices"
                            :label="`${notices ? 'Con' : 'Sin'} Avisos`"
                        ></v-checkbox>
                    </v-col>
                    <v-col cols="6">
                        <kimsa-text-field
                            label="Tema de Reunión"
                            placeholder="Tema"
                            bolder-label
                            outlined dense
                            force-value
                            :value="name"
                            @change="name = $event"
                        />
                    </v-col>
                    <v-col cols="6">
                        <kimsa-date-picker
                            label="Fecha"
                            bolder-label
                            outlined solo
                            flat dense
                            force-value
                            :value="date"
                            @change="date = $event"
                        />
                    </v-col>
                    <v-col cols="6">
                        <kimsa-date-picker
                            label="Hora inicial"
                            bolder-label
                            outlined solo
                            flat dense
                            force-value
                            time
                            :max="endHour"
                            :value="startHour"
                            @change="startHour = $event"
                        />
                    </v-col>
                    <v-col cols="6">
                        <kimsa-date-picker
                            label="Hora final"
                            bolder-label
                            outlined solo
                            flat dense
                            force-value
                            time
                            :min="startHour"
                            :value="endHour"
                            @change="endHour = $event"
                        />
                    </v-col>
                    <v-col cols="12">
                        <kimsa-textarea-field
                            label="Puntos a tratar"
                            placeholder="Describa los puntos que se trataran en la videoconferencia"
                            outlined solo flat dense force-value bolder-label
                            :value="description"
                            @change="description = $event"
                        />
                    </v-col>
                    <v-col cols="12">
                        <add-user-to-videoconference
                            :values="users"
                            @change="users = $event"
                        />
                    </v-col>
                    <v-col cols="12" align="center">
                        <v-btn @click="editMode ? edit() : create()" tile color="primary" depressed :disabled="disableCreate" :loading="loading">
                            {{ editMode ? 'GUARDAR' : 'CREAR' }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import Axios from "@/utils/Axios";
import KimsaSelect from "@/components/common/inputs/KimsaSelect";
import {mapMutations} from "vuex";
import KimsaDatePicker from "@/components/common/inputs/KimsaDatePicker";
import AddUserToVideoconference from "@/components/meeting/AddUserToVideoconference";
import KimsaTextareaField from "@/components/common/inputs/KimsaTextareaField";
import KimsaTextField from "@/components/common/inputs/KimsaTextField";
export default {
    name: "CreateRoomDialog",
    components: {KimsaTextField, KimsaTextareaField, AddUserToVideoconference, KimsaDatePicker, KimsaSelect},
    data() {
        return {
            dialog: false,
            loading: false,
            editMode: false,
            videoconference: null,
            clients: [],
            client: null,
            notices: false,
            name: null,
            date: null,
            startHour: null,
            endHour: null,
            description: '',
            users: [],
        }
    },
    async mounted() {
        const vm = this
        await vm.loadClients()
    },
    computed: {
        disableCreate(vm = this) {
            let userRequireData = !vm.users.find(user => !user.name || !vm.validEmail(user.email))
            return !vm.name || !vm.date || !userRequireData
        },
    },
    methods: {
        ...mapMutations(['setDialogActive']),
        async show() {
            const vm = this
            if(!vm.editMode) vm.setDefaultDate()
            vm.dialog = true
            vm.setDialogActive(true)
        },
        hide() {
            const vm = this
            vm.dialog = false
            vm.setDialogActive(false)
            setTimeout(() => {
                vm.reset()
            },500)
        },
        reset() {
            const vm = this
            vm.editMode = false
            vm.videoconference = null
            vm.clients = []
            vm.client = null
            vm.notices = false
            vm.name = ''
            vm.date = null
            vm.startHour = null
            vm.endHour = null
            vm.description = ''
            vm.users = []
        },
        validEmail(email) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(email) || false
        },
        async loadClients() {
            const vm = this
            await Axios.get(`clients`).then(res => {
                // console.log('res loadClients', res)
                vm.clients = res.data.result.clients
            }).catch(er => {
                console.log('error loadClients', er)
            })
        },
        async loadVideoconference(id) {
            const vm = this
            await Axios.get(`videoconference/${id}`).then(res => {
                // console.log('res loadVideoconference', res)
                vm.videoconference = res.data.result.videoconference
                vm.editMode = true
                vm.client = vm.videoconference.client_id || null
                vm.name = vm.videoconference.name || ''
                vm.notices = vm.videoconference.notices || false
                vm.date = vm.$moment(vm.videoconference.date).format('YYYY-MM-DD')
                vm.startHour = vm.videoconference.start_hour || null
                vm.endHour = vm.videoconference.end_hour || null
                vm.description = vm.videoconference.description || ''
                vm.users = vm.videoconference.users || []
                vm.show()
            }).catch(er => {
                console.log('error loadVideoconference', er)
            })
        },
        async create() {
            const vm = this
            vm.loading = true

            let payload = {
                name: vm.name,
                notices: vm.notices,
                date: vm.date,
                start_hour: vm.startHour,
                end_hour: vm.endHour,
                description: vm.description,
                users: vm.users,
            }

            if(vm.client) payload.client_id = vm.client

            console.log('payload', payload)

            await Axios.post(`videoconference/new`, payload).then(res => {
                // console.log('res create',res)
                vm.toast('Videoconferencia creada')
                vm.$emit('success')
            }).catch(error => {
                vm.toast(error, 'error')
            }).finally(() => {
                vm.loading = false
                vm.hide()
            })
        },
        async edit() {
            const vm = this
            vm.loading = true

            let payload = {
                client_id: vm.client,
                name: vm.name,
                notices: vm.notices,
                date: vm.date,
                start_hour: vm.startHour,
                end_hour: vm.endHour,
                description: vm.description,
                users: vm.users,
            }

            await Axios.patch(`videoconference/${vm.videoconference.id}/edit`, payload).then(res => {
                // console.log('res edit', res)
                vm.toast('Videoconferencia editada')
                vm.$emit('success')
            }).catch(error => {
                console.log('error edit', error)
            }).finally(() => {
                vm.loading = false
                vm.hide()
            })
        },
        setDefaultDate() {
            const vm = this
            let date = vm.$moment()
            vm.date = date.format('YYYY-MM-DD')
            vm.startHour = date.format('HH:mm')
            vm.endHour = date.add(1, 'hours').format('HH:mm')
        }
    },
    watch: {
        dialog(val) {if(val) this.loadClients()}
    },
}
</script>

<style scoped>

.label {
    font-size: 15px;
    color: #333333;
}

</style>